import Vue from 'vue'
import App from './App.vue'
import axios from 'axios';
import VueAxios from 'vue-axios';
import Embed from 'v-video-embed';
Vue.config.productionTip = false

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
Vue.use(VueAxios, axios);
Vue.use(Embed);

new Vue({
  render: h => h(App),
}).$mount('#app')
