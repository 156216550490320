<template>
  <div id="app">
    <LandingView/>
  </div>
</template>

<script>
import LandingView from "./views/LandingView";
export default {
  name: 'App',
  components: {
    LandingView
  },

}
</script>

<style lang="scss">
  @import 'assets/styles/style.scss';
  body {
    font-family: 'Open Sans', Helvetica, 'Arial', sans-serif;
    font-size: 16px;
    line-height: 1.2;
  }
</style>
