<template>
    <div class="main-container">
        <div class="pre-loader" v-if="loading">
            <div id="circle4"></div>
        </div>
        <div class="inner" v-else>
            <template v-for="(block, n) of page.blocks">
                <div class="block-wrapper" :key="n" :style="setBackground(block.styles)">
                    <div class="block-item" :style="setPadding(block.styles)">
                        <component :is="block.type" :content="block.content"/>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    export default {
        name: "LandingView",
        components: {
            taplinkTextarea: () => import("../components/BlockText"),
            taplinkButton: () => import("../components/BlockButton"),
            taplinkBanner: () => import("../components/BlockBanner"),
            taplinkVideo: () => import("../components/BlockVideo"),
            taplinkSeparator: () => import("../components/BlockSeparator"),
            taplinkSocials: () => import("../components/BlockSocial"),
            taplinkSlider: () => import("../components/BlockSlider"),
            taplinkHtml: () => import("../components/BlockHtml"),
        },
        data() {
            return {
                page: null,
                blocks: null,
                loading: true,
            }
        },
        created() {
            this.onload();
        },
        methods: {
           async onload() {
               const {data} = await this.axios.get(`/landings/public/?url=${window.location.href}`)
               //const {data} = await this.axios.get(`/landings/public/?url=http://lp.platformofficial.ru/robot/`)
               this.page = data;
               this.loading = false
            },
            setPadding(styles) {
                if (!styles.isEnabled) return;
                const result = {};
                for (let side in styles.pd) {
                    result[side] = styles.pd[side] + "px";
                }
                return result;
            },
            setBackground(styles) {
                if (!styles.isEnabled) return;
                let background = {};
                if (styles.bg.type === "color") {
                    background.backgroundColor = styles.bg.color1;
                } else if (styles.bg.type === "gradient") {
                    background.backgroundImage = `linear-gradient(${styles.bg.color1},${styles.bg.color2})`;
                }
                if (styles.bg.image) {
                    background.backgroundImage = `url('${styles.bg.image.url}'), linear-gradient(${styles.bg.color1},${styles.bg.color2})`;
                }
                return background;
            }
        }
    }
</script>

<style lang="scss">

    .main-container {
        width: 100%;
        margin: 0 auto;
        min-height: 100vh;
        background-size: cover !important;
        background-attachment: fixed !important;
        background-repeat: no-repeat !important;

    }
    .block {
        &-wrapper {
            padding: 7px 10px;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
        }
        &-item {
            max-width: 100%;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            @media screen and (min-width: 768px) {
                max-width: 620px;
            }
            @media screen and (min-width: 992px) {
                max-width: 720px;
            }
            @media screen and (min-width: 1280px) {
                max-width: 820px;
            }
        }
    }
</style>
